import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PropTypes from "prop-types";

function getQueryParam(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

function HelpTooltip(props) {
  const renderTooltip = (tooltipProps) => (
    <Tooltip id="button-tooltip" {...tooltipProps}>
      {props.descString}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <i className="bi bi-question-circle"></i>
    </OverlayTrigger>
  );
}
HelpTooltip.propTypes = {
  descString: PropTypes.string.isRequired,
};

function SortingSelect(props) {
  return (
    <InputGroup>
      <InputGroup.Text>Sorting</InputGroup.Text>
      <Form.Select
        aria-label="Sorting"
        value={props.sorting}
        onChange={(event) => props.onChange(event.target.value)}
      >
        <option value="single_delta_percentage">
          Sort by single day potential
        </option>
        <option value="consecutive_delta_percentage">
          Sort by consecutive days potential
        </option>
        <option value="optimum_delta_percentage">
          Sort by optimum consecutive days potential
        </option>
      </Form.Select>
    </InputGroup>
  );
}
SortingSelect.propTypes = {
  sorting: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function FilterTextField({ filterStrings, onChange }) {
  const [inputValue, setInputValue] = useState(filterStrings);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onChange(inputValue);
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [inputValue, onChange]);

  useEffect(() => {
    if (inputValue !== filterStrings) {
      setInputValue(filterStrings);
    }
  }, [filterStrings]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <InputGroup>
      <InputGroup.Text>Filter</InputGroup.Text>
      <Form.Control
        aria-label="Filter"
        placeholder="Space-separated symbols"
        value={inputValue}
        onChange={handleInputChange}
      />
    </InputGroup>
  );
}
FilterTextField.propTypes = {
  filterStrings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function LossFilterTextField({ lossAmount, onChange }) {
  const [inputValue, setInputValue] = useState(lossAmount);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      onChange(inputValue);
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [inputValue, onChange]);

  useEffect(() => {
    if (inputValue !== lossAmount) {
      setInputValue(lossAmount);
    }
  }, [lossAmount]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <InputGroup>
      <InputGroup.Text>Loss Filter</InputGroup.Text>
      <Form.Control
        type="range" class="form-range" min="0.0000003" max="0.00001" step="0.0000001"
        aria-label="Loss Filter"
        placeholder="e.g. 1.5e-6"
        value={inputValue}
        onChange={handleInputChange}
      />
      <InputGroup.Text>{parseFloat(inputValue).toExponential(2)}</InputGroup.Text>
    </InputGroup>
  );
}
LossFilterTextField.propTypes = {
  filterStrings: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function App() {
  const [tickers, setTickers] = useState([]);
  const [realTimePrices, setRealTimePrices] = useState({});
  const [economicCalendar, setEconomicCalendar] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const [sorting, setSorting] = useState(
    getQueryParam("sorting") || "consecutive_delta_percentage"
  );
  const [filterStrings, setFilterStrings] = useState(
    getQueryParam("filter") || ""
  );
  const [lossAmount, setLossAmount] = useState(
    getQueryParam("loss") || "0.0001"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCalendar();
  }, []);

  useEffect(() => {
    fetchPredictions();
  }, [sorting, currentPage, filterStrings, lossAmount]);

  const fetchCalendar = () => {
    fetch(`https://hybridengine.syraxius.com/api/get_economic_calendar`)
      .then((res) => res.json())
      .then((data) => {
        setEconomicCalendar(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const processRealTimePrices = (realTimePrices, tickers) => {
    let ticker_dict = {};
    for (let ticker of tickers) {
      ticker_dict[ticker.symbol] = ticker;
    }
    for (let [_ticker, realTimePrice] of Object.entries(realTimePrices)) {
      let ticker = ticker_dict[_ticker];
      realTimePrice["current_delta"] =
        realTimePrice["latest_price"] - ticker["y"][0];
      realTimePrice["current_delta_percentage"] =
        (realTimePrice["current_delta"] / ticker["y"][0]) * 100;
      realTimePrice["current_delta_color"] =
        realTimePrice["current_delta"] < 0 ? "red" : "green";

      realTimePrice["single_day_target_entry_price"] =
        realTimePrice["latest_price"];
      realTimePrice["single_day_target_exit_price"] = ticker["y"][1];
      realTimePrice["single_day_delta"] =
        ticker["y"][1] - realTimePrice["latest_price"];
      realTimePrice["single_day_delta_percentage"] =
        (realTimePrice["single_day_delta"] / realTimePrice["latest_price"]) *
        100;
      realTimePrice["single_day_delta_color"] =
        realTimePrice["single_day_delta"] < 0 ? "red" : "green";
    }
    return realTimePrices;
  };

  const processTicker = (tickers) => {
    for (const ticker of tickers) {
      let dy_array = ticker.dy_n.split(",").map(parseFloat);
      ticker["dy"] = dy_array;
      ticker["total_dy"] = dy_array.length;

      ticker["y"] = [ticker["y_0"]];
      let curr_y = ticker["y"][0];
      for (const dy of ticker["dy"]) {
        curr_y += dy;
        ticker["y"].push(curr_y);
      }

      ticker["single_day_target_entry_price"] = ticker["y"][0];
      ticker["single_day_target_exit_price"] = ticker["y"][1];
      ticker["single_day_delta"] = ticker["y"][1] - ticker["y"][0];
      ticker["single_day_delta_percentage"] =
        (ticker["single_day_delta"] / ticker["y"][0]) * 100;
      ticker["single_day_delta_color"] =
        ticker["single_day_delta"] < 0 ? "red" : "green";

      ticker["consecutive_days_target_entry_price"] = ticker["y"][0];
      ticker["consecutive_days_target_exit_price"] =
        ticker["y"][ticker["consecutive_exit_day"]];
      ticker["consecutive_days_delta"] = ticker["consecutive_delta"];
      ticker["consecutive_days_delta_percentage"] =
        ticker["consecutive_delta_percentage"] * 100;
      ticker["consecutive_days_delta_color"] =
        ticker["consecutive_days_delta"] < 0 ? "red" : "green";
      ticker["consecutive_days_min_price_index"] = 0;
      ticker["consecutive_days_max_price_index"] =
        ticker["consecutive_exit_day"];

      ticker["optimum_consecutive_days_target_entry_price"] =
        ticker["y"][ticker["optimum_entry_day"]];
      ticker["optimum_consecutive_days_target_exit_price"] =
        ticker["y"][ticker["optimum_exit_day"]];
      ticker["optimum_consecutive_days_delta"] = ticker["optimum_delta"];
      ticker["optimum_consecutive_days_delta_percentage"] =
        ticker["optimum_delta_percentage"] * 100;
      ticker["optimum_consecutive_days_delta_color"] =
        ticker["optimum_consecutive_days_delta"] < 0 ? "red" : "green";
      ticker["optimum_consecutive_days_min_price_index"] =
        ticker["optimum_entry_day"];
      ticker["optimum_consecutive_days_max_price_index"] =
        ticker["optimum_exit_day"];
    }
    return tickers;
  };

  const fetchRealTimePrices = (tickers) => {
    setRealTimePrices({})
    let fetch_tickers = [];
    for (let ticker of tickers) {
      fetch_tickers.push(ticker.symbol);
    }
    let tickerStrings = fetch_tickers.join(",");
    fetch(
      `https://hybridengine.syraxius.com/api/get_ticker_real_time_prices?tickers=${tickerStrings}`
    )
      .then((res) => res.json())
      .then((data) => {
        setRealTimePrices(
          processRealTimePrices(data.real_time_prices, tickers)
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchPredictions = () => {
    setTickers([]);
    setIsLoading(true);
    setIsFailed(false);
    fetch(
      `https://hybridengine.syraxius.com/api/get_ticker_predictions?page=${currentPage}&results_per_page=20&sort_type=${sorting}&search=${filterStrings.toUpperCase()}&test_loss=${lossAmount}`
    )
      .then((res) => res.json())
      .then((data) => {
        setTickers(processTicker(data.ticker_summaries));
        setTotalPages(data.total_pages);
        setIsLoading(false);
        fetchRealTimePrices(data.ticker_summaries);
      })
      .catch((err) => {
        setIsFailed(true);
        console.log(err.message);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams();
    if (sorting !== "consecutive_delta_percentage") {
      queryParams.set("sort_type", sorting);
    }
    if (filterStrings) {
      queryParams.set("filter", filterStrings);
    }
    if (lossAmount && lossAmount !== "0.0001") {
      queryParams.set("loss", lossAmount);
    }
    if (currentPage !== 1) {
      queryParams.set("page", currentPage);
    }
    if (queryParams.size) {
      window.history.replaceState(null, "", `/?${queryParams.toString()}`);
    } else {
      window.history.replaceState(null, "", `/`);
    }
  }, [sorting, filterStrings, lossAmount, currentPage]);

  let handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const sortType = queryParams.get("sort_type") || "consecutive_delta_percentage";
      const filter = queryParams.get("filter") || "";
      const page = parseInt(queryParams.get("page")) || 1;

      setSorting(sortType);
      setFilterStrings(filter);
      setCurrentPage(page);
    };

    // Listen for `popstate` event
    window.addEventListener("popstate", handlePopState);

    // Sync state with current URL parameters on mount
    handlePopState();

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const PaginationBar = () => {
    const getPaginationRange = () => {
      const totalVisible = 7;
      let startPage, endPage;

      if (totalPages <= totalVisible) {
        startPage = 1;
        endPage = totalPages;
      } else {
        const maxPagesBeforeCurrentPage = Math.floor(totalVisible / 2);
        const maxPagesAfterCurrentPage = Math.ceil(totalVisible / 2) - 1;

        if (currentPage <= maxPagesBeforeCurrentPage) {
          startPage = 1;
          endPage = totalVisible;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          startPage = totalPages - totalVisible + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - maxPagesBeforeCurrentPage;
          endPage = currentPage + maxPagesAfterCurrentPage;
        }
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    };

    const paginationRange = getPaginationRange();

    return (
      <nav>
        <ul className="pagination m-0">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
          </li>
          {paginationRange.map((page) => (
            <li
              key={page}
              className={`page-item ${page === currentPage ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  let LoadingBlock = () => {
    if (isFailed) {
      return (
        <Alert className="mt-4" variant="danger">
          Unable to fetch results from HybridEngine API. Please contact Kelvin.
        </Alert>
      );
    }
    if (isLoading) {
      return (
        <Alert className="mt-4" variant="info">
          <Spinner animation="border" size="sm" /> Loading... This shouldn't take more than five seconds!
        </Alert>
      );
    }
    if (tickers.length === 0) {
      return (
        <Alert className="mt-4" variant="warning">
          No results to display. Please refine your search query or choose one of the suggested filters above.
        </Alert>
      );
    }
    return null;
  };

  const RefreshRealTimePriceButton = () => {
    return (
      <a onClick={() => fetchRealTimePrices(tickers)} style={{cursor: "default"}}>&#128260; Refresh</a>
    )
  }

  const RealTimePrice = ({ ticker }) => {
    if (!realTimePrices[ticker.symbol]) {
      return (
        <span>
          <div className="h6">
            Current Price:{" "}
            <HelpTooltip descString="Current Price / Actual Last Day Closing Price" />
          </div>
          <div className="h2">
            <Spinner /> Loading...
          </div>
          <div className="h6">
            Predicted Closing:{" "}
            <HelpTooltip descString="Predicted Upcoming Day Closing Price / Current Price" />
          </div>
          <div className="h2">
            <Spinner /> Loading...
          </div>
          <hr />
        </span>
      );
    }

    return (
      <span>
        <div className="h6">
          Current Price:{" "}
          <HelpTooltip descString="Current Price / Actual Last Day Closing Price" /> <RefreshRealTimePriceButton />
        </div>
        <div className="h2">
          {realTimePrices[ticker.symbol]["latest_price"].toFixed(2)} (
          <p
            style={{
              display: "inline",
              color: realTimePrices[ticker.symbol]["current_delta_color"],
            }}
          >
            {realTimePrices[ticker.symbol]["current_delta"] < 0 ? "" : "+"}
            {realTimePrices[ticker.symbol]["current_delta"].toFixed(2)}{" "}
            {realTimePrices[ticker.symbol]["current_delta_percentage"] < 0
              ? ""
              : "+"}
            {realTimePrices[ticker.symbol]["current_delta_percentage"].toFixed(
              2
            )}
            %
          </p>
          )
        </div>
        <div className="h6">
          Predicted Closing:{" "}
          <HelpTooltip descString="Predicted Upcoming Day Closing Price / Current Price" />
        </div>
        <div className="h2">
          {ticker["y"][1].toFixed(2)} (
          <p
            style={{
              display: "inline",
              color: realTimePrices[ticker.symbol]["single_day_delta_color"],
            }}
          >
            {realTimePrices[ticker.symbol]["single_day_delta"] < 0 ? "" : "+"}
            {realTimePrices[ticker.symbol]["single_day_delta"].toFixed(2)}{" "}
            {realTimePrices[ticker.symbol]["single_day_delta_percentage"] < 0
              ? ""
              : "+"}
            {realTimePrices[ticker.symbol][
              "single_day_delta_percentage"
            ].toFixed(2)}
            %
          </p>
          )
        </div>
        <hr />
      </span>
    );
  };

  const getNextWeekday = (startDate, daysToAdd) => {
    let date = new Date(startDate);
    while (daysToAdd > 0) {
      date.setDate(date.getDate() + 1);
      // Skip weekends
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        daysToAdd--;
      }
    }
    return date;
  };

  const getDayAbbreviation = (date) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[date.getDay()];
  };

  let PredictionTable = ({ ticker }) => {
    let output = [];
    let startDate = new Date(ticker.date_last_data); // Start from the last data date

    for (let i = 1; i < ticker["total_dy"]; i++) {
      let nextDate = getNextWeekday(startDate, i);
      let dateString = nextDate.toISOString().split("T")[0]; // Convert date to YYYY-MM-DD format
      let dayAbbreviation = getDayAbbreviation(nextDate); // Get day abbreviation

      let price = ticker["y"][i];
      let priceDelta = ticker["y"][i] - ticker["y"][i - 1];
      let priceDeltaPercentage = (priceDelta / price) * 100;
      let color = priceDeltaPercentage < 0 ? "red" : "green";
      let priceDeltaCum = ticker["y"][i] - ticker["y"][0];
      let priceDeltaPercentageCum = (priceDeltaCum / ticker["y"][0]) * 100;
      let colorCum = priceDeltaPercentageCum < 0 ? "red" : "green";
      let isOptimumEntryDay = i === ticker.optimum_entry_day;
      let isOptimumExitDay = i === ticker.optimum_exit_day;
      let matchedRecords = [];
      if (economicCalendar && economicCalendar.records) {
        for (let record of economicCalendar.records) {
          if (record[0] === dateString) {
            matchedRecords.push(record);
          }
        }
      }
      output.push([
        dateString,
        dayAbbreviation,
        price,
        priceDelta,
        priceDeltaPercentage,
        color,
        priceDeltaCum,
        priceDeltaPercentageCum,
        colorCum,
        isOptimumEntryDay,
        isOptimumExitDay,
        matchedRecords,
      ]);
    }
    return (
      <div
        style={{
          display: "inline-block",
          whiteSpace: "nowrap",
          maxHeight: "240px",
          overflow: "auto",
        }}
      >
        {output.map(
          ([
            dateString,
            dayAbbreviation,
            price,
            priceDelta,
            priceDeltaPercentage,
            color,
            priceDeltaCum,
            priceDeltaPercentageCum,
            colorCum,
            isOptimumEntryDay,
            isOptimumExitDay,
            matchedRecords,
          ]) => {
            return (
              <div style={{ display: "block" }} key={dateString}>
                <b>
                  {dateString}, {dayAbbreviation}
                </b>
                : {price.toFixed(2)} (Single:{" "}
                <span
                  style={{
                    display: "inline",
                    color: color,
                  }}
                >
                  {priceDelta.toFixed(2)} {priceDeltaPercentage.toFixed(2)}%
                </span>
                , Cumulative:{" "}
                <span
                  style={{
                    display: "inline",
                    color: colorCum,
                  }}
                >
                  {priceDeltaCum.toFixed(2)}{" "}
                  {priceDeltaPercentageCum.toFixed(2)}%
                </span>
                )
                <ul className="m-0">
                  {isOptimumEntryDay ? (
                    <li>
                      <b style={{ color: "blue" }}>Optimum entry day</b>
                    </li>
                  ) : (
                    ""
                  )}
                  {isOptimumExitDay ? (
                    <li>
                      <b style={{ color: "blue" }}>Optimum exit day</b>
                    </li>
                  ) : (
                    ""
                  )}
                  {matchedRecords.map((record) => {
                    return (
                      <li key={record}>
                        {record[1]} - {record[3]}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          }
        )}
      </div>
    );
  };

  return (
    <div>
      <Navbar expand="lg" className="navbar-dark bg-dark">
        <Container fluid="xl">
          <Navbar.Brand href="/">
            <Image
              src="/logo192.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            HybridEngine (BETA)
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Form
                inline="true"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Row>
                  <Col
                    className="mt-2 mt-sm-2 mt-lg-0 mt-xl-0 mt-xxl-0"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="4"
                    xl="4"
                    xxl="4"
                  >
                    <SortingSelect sorting={sorting} onChange={setSorting} />
                  </Col>
                  <Col
                    className="mt-2 mt-sm-2 mt-lg-0 mt-xl-0 mt-xxl-0"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="4"
                    xl="4"
                    xxl="4"
                  >
                    <FilterTextField
                      filterStrings={filterStrings}
                      onChange={setFilterStrings}
                    />
                  </Col>
                  <Col
                    className="mt-2 mt-sm-2 mt-lg-0 mt-xl-0 mt-xxl-0"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="4"
                    xl="4"
                    xxl="4"
                  >
                    <LossFilterTextField
                      lossAmount={lossAmount}
                      onChange={setLossAmount}
                    />
                  </Col>
                </Row>
              </Form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid="xl">
          <Nav navbarScroll>
            <Nav.Link onClick={() => setFilterStrings("")}>Default</Nav.Link>
            <Nav.Link
              onClick={() => {
                setFilterStrings("GOOGL GOOG AMZN AAPL META MSFT NVDA TSLA")
                setCurrentPage(1)
              }}
            >
              Magnificent 7
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setFilterStrings("SPY ^GSPC QQQ ^IXIC DIA ^DJI IWM ^RUT")
                setCurrentPage(1)
              }}
            >
              Indexes
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                setFilterStrings("XLE XLU XLK XLB XLP XLY XLI XLC XLV XLF XLRE")
                setCurrentPage(1)
              }}
            >
              Select Sector
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container fluid="xl">
        <Row className="mt-4">
          <Col>
            <PaginationBar />
          </Col>
        </Row>
        <LoadingBlock />
        {tickers.map((ticker) => (
          <Row key={ticker.symbol}>
            <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={4}>
              <Card className="mt-4">
                <Card.Body>
                  <Card.Title>
                    <div className="h1">
                      <a
                        href={
                          "https://finance.yahoo.com/quote/" +
                          ticker.symbol +
                          "/chart?p=" +
                          ticker.symbol
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {ticker.symbol}
                      </a>
                    </div>
                  </Card.Title>
                  <hr />
                  <p>{ticker["latest_timestamp_formatted"]}</p>
                  <RealTimePrice ticker={ticker} />
                  <div className="h6">
                    Single Day{" "}
                    <HelpTooltip descString="Buy today and sell today." />
                  </div>
                  <p>
                    <b>Enter</b> 0 trading day(s) from now
                    <br />
                    <b>Exit</b> 0 trading day(s) from now
                    <br />
                    <b>Buy below</b>{" "}
                    {ticker["single_day_target_entry_price"].toFixed(2)}
                    <br />
                    <b>Sell above</b>{" "}
                    {ticker["single_day_target_exit_price"].toFixed(2)}
                    <br />
                    <b>Potentially gain</b>{" "}
                    <span
                      style={{
                        display: "inline",
                        color: ticker["single_day_delta_color"],
                      }}
                    >
                      {ticker["single_day_delta_percentage"].toFixed(2)}%
                    </span>{" "}
                    from current price
                  </p>
                  <hr />
                  <div className="h6">
                    Consecutive Days{" "}
                    <HelpTooltip descString="Buy today and sell after holding for n days." />
                  </div>
                  <p>
                    <b>Enter</b> 0 trading day(s) from now
                    <br />
                    <b>Exit</b> {ticker["consecutive_days_max_price_index"]}{" "}
                    trading day(s) from now
                    <br />
                    <b>Buy below</b>{" "}
                    {ticker["consecutive_days_target_entry_price"].toFixed(2)}
                    <br />
                    <b>Sell above</b>{" "}
                    {ticker["consecutive_days_target_exit_price"].toFixed(2)}
                    <br />
                    <b>Potentially gain</b>{" "}
                    <span
                      style={{
                        display: "inline",
                        color: ticker["consecutive_days_delta_color"],
                      }}
                    >
                      {ticker["consecutive_days_delta_percentage"].toFixed(2)}%
                    </span>{" "}
                    from current price
                  </p>
                  <hr />
                  <div className="h6">
                    Optimum Consecutive Days{" "}
                    <HelpTooltip descString="Buy on an optimal date and sell after holding for n days." />
                  </div>
                  <p>
                    <b>Enter</b>{" "}
                    {ticker["optimum_consecutive_days_min_price_index"]} trading
                    day(s) from now
                    <br />
                    <b>Exit</b>{" "}
                    {ticker["optimum_consecutive_days_max_price_index"]} trading
                    day(s) from now
                    <br />
                    <b>Buy below</b>{" "}
                    {ticker[
                      "optimum_consecutive_days_target_entry_price"
                    ].toFixed(2)}
                    <br />
                    <b>Sell above</b>{" "}
                    {ticker[
                      "optimum_consecutive_days_target_exit_price"
                    ].toFixed(2)}
                    <br />
                    <b>Potentially gain</b>{" "}
                    <span
                      style={{
                        display: "inline",
                        color: ticker["consecutive_days_delta_color"],
                      }}
                    >
                      {ticker[
                        "optimum_consecutive_days_delta_percentage"
                      ].toFixed(2)}
                      %
                    </span>{" "}
                    from lowest price
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={8}>
              <Card className="mt-4">
                <Card.Body>
                  <Card.Title>
                    <div className="h6">{ticker.symbol} Prediction Charts</div>
                  </Card.Title>
                  <a
                    key={ticker.image_path}
                    href={
                      "https://hybridengine.syraxius.com/images/" +
                      ticker.image_path
                    }
                    style={{
                      display:
                        ticker.image_path === ticker["image_path"]
                          ? "block"
                          : "none",
                    }}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        "https://hybridengine.syraxius.com/images/" +
                        ticker.image_path
                      }
                    />
                  </a>
                  <center>
                    <div>
                      <span className="fw-bold">Model:</span>{" "}
                      {ticker["model_manifest"]}
                    </div>
                    <div>
                      <span className="fw-bold">Loss (Train / Test):</span>{" "}
                      {ticker["eval_train_loss"].toExponential(2)}{" / "}{ticker["eval_test_loss"].toExponential(2)}
                    </div>
                    <div>
                      <span className="fw-bold">Last Seen Closing:</span>{" "}
                      {ticker["date_last_data"]}{" "}
                    </div>
                    {ticker["business_days_between"] > 0 ? (
                      <Alert className="mt-4 mb-0" variant="warning">
                        <Alert.Heading>WARNING</Alert.Heading>
                        Use cautiously. Model is pending recomputation! Data,
                        prediction and graph is from{" "}
                        {ticker["business_days_between"]} day(s) ago!
                      </Alert>
                    ) : null}
                  </center>
                  <hr />
                  <Card.Title>
                    <div className="h6">{ticker.symbol} Prediction Table</div>
                  </Card.Title>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PredictionTable ticker={ticker} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
        <Row className="mt-4 mb-4">
          <Col>
            <PaginationBar />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
